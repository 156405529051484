const Impressum = () => (
  <div>
    <h2>Impressum</h2>
    <p>
      Thomas Dietrich
      <br />
      Ottersbach 41
      <br />
      A-8452 Großklein
      <br />
      <br />
      <b>E-Mail: </b> <a href="mailto:mail@infizierte.at">mail@infizierte.at</a>
    </p>
  </div>
);

export default Impressum;
